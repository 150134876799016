import { ROUTES } from '@/constants/routes/default';
import Link from 'next/link';

const NotFoundMC = ({ text }: { text?: string }) => (
  <div className="flex flex-col items-center justify-center max-md:mb-10 md:mb-16 px-4">
    <div className="text-zombie max-md:text-[54px] md:text-[84px] font-bold">404</div>
    <div className="text-white1 mt-3 max-w-[440px] text-center">
      {text ?? 'That page doesn’t exist. Head to our home page instead or go search for jobs here'}
    </div>
    <Link
      className="h-10 mt-6 px-12 flex mx-auto items-center border-none rounded-lg font-bold !text-cod-gray1 bg-zombie hover:!bg-white1 default-transition"
      href={ROUTES.jobs}
    >
      Search Jobs
    </Link>
  </div>
);

export default NotFoundMC;
