import { ROUTES } from '@/constants/routes/default';
import Link from 'next/link';

const NotFoundKD = ({ text }: { text?: string }) => (
  <div className="flex flex-col items-center justify-center max-md:my-10 md:my-20 px-4">
    <div className="text-blizzard-blue max-md:text-[54px] md:text-[84px] font-bold">404</div>
    <div className="text-white1 mt-3 max-w-[480px] text-center">
      {text ?? 'That page doesn’t exist. Head to our home page instead or go search for jobs here'}
    </div>
    <Link
      className="mt-8 !px-16 h-10 flex font-bold items-center border-none bg-blizzard-blue rounded-lg text-cod-gray hover:bg-seagull default-transition"
      href={ROUTES.jobs}
    >
      Search Jobs
    </Link>
  </div>
);

export default NotFoundKD;
