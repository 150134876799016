import { ROUTES } from '@/constants/routes/default';
import Link from 'next/link';

const NotFoundKG = ({ text }: { text?: string }) => (
  <div className="smooth-image min-h-[calc(100vh-223px)] flex flex-col items-center px-4">
    <div className="flex flex-col items-center max-w-[600px] mt-10 p-10 bg-white pure-shadow border border-gray-900">
      <div className="text-shark max-md:text-[54px] md:text-[84px] font-bold">404</div>
      <div className="text-shark mt-3 max-w-[520px] text-center">
        {text ??
          'That page doesn’t exist. Head to our home page instead or go search for jobs here'}
      </div>
      <Link
        className="mt-10 h-auto cursor-pointer border-none py-2 max-md:px-12 md:px-20 text-center hover:bg-black bg-denim text-white rounded-none default-transition"
        href={ROUTES.jobs}
      >
        Search Jobs
      </Link>
    </div>
  </div>
);

export default NotFoundKG;
