import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/dc/DefaultLayoutDC/DefaultLayoutDC.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-open-sans\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/dc/DefaultLayoutDC/DefaultLayoutDC.tsx\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\"],\"display\":\"swap\",\"variable\":\"--font-dm-mono\"}],\"variableName\":\"dmMono\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/mc/DefaultLayoutMC/DefaultLayoutMC.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-open-sans\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/mc/DefaultLayoutMC/DefaultLayoutMC.tsx\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\"],\"display\":\"swap\",\"variable\":\"--font-dm-mono\"}],\"variableName\":\"dmMono\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/jc/DefaultLayoutJC/DefaultLayoutJC.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-open-sans\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/kg/DefaultLayoutKG/DefaultLayoutKG.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-open-sans\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/kg/DefaultLayoutKG/DefaultLayoutKG.tsx\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\"],\"display\":\"swap\",\"variable\":\"--font-dm-mono\"}],\"variableName\":\"dmMono\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/cn/DefaultLayoutCN/DefaultLayoutCN.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-open-sans\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/kd/DefaultLayoutKD/DefaultLayoutKD.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-open-sans\"}],\"variableName\":\"openSans\"}");
;
import(/* webpackMode: "eager" */ "/app/public/cn/homepage-header-1.svg");
;
import(/* webpackMode: "eager" */ "/app/public/cn/logo.svg");
;
import(/* webpackMode: "eager" */ "/app/public/dc/footer-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/public/mc/logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/kd/DefaultLayoutKD/DefaultLayoutKD.tsx\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\"],\"display\":\"swap\",\"variable\":\"--font-dm-mono\"}],\"variableName\":\"dmMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/jc/not-found.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/kd/not-found.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/kg/not-found.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/mc/not-found.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/cn/HeaderCN.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/dc/DefaultLayoutDC/DefaultLayoutDC.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/cn/DefaultLayoutCN/DefaultLayoutCN.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/HeaderLinks.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/jc/DefaultLayoutJC/DefaultLayoutJC.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/jc/DefaultLayoutJC/DefaultLayoutJC.tsx\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\"],\"display\":\"swap\",\"variable\":\"--font-dm-mono\"}],\"variableName\":\"dmMono\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/components/cn/DefaultLayoutCN/DefaultLayoutCN.tsx\",\"import\":\"DM_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\"],\"display\":\"swap\",\"variable\":\"--font-dm-mono\"}],\"variableName\":\"dmMono\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/jc/HeaderJC.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/mc/DefaultLayoutMC/DefaultLayoutMC.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/kg/DefaultLayoutKG/DefaultLayoutKG.css");
;
import(/* webpackMode: "eager" */ "/app/src/components/kd/DefaultLayoutKD/DefaultLayoutKD.css");
