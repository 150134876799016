import { ROUTES } from '@/constants/routes/default';
import Link from 'next/link';

const NotFoundJC = ({ text }: { text?: string }) => (
  <div className="flex flex-col items-center justify-center max-md:my-10 md:my-16 px-4">
    <div className="text-cod-gray max-md:text-[54px] md:text-[84px] font-bold">404</div>
    <div className="text-cod-gray mt-3 max-w-[520px] text-center">
      {text ?? 'That page doesn’t exist. Head to our home page instead or go search for jobs here'}
    </div>
    <Link
      className="mt-10 flex items-center h-10 cursor-pointer border-none rounded-md py-2 max-md:px-12 md:px-20 bg-boston-blue uppercase text-sm text-white font-bold text-center hover:bg-cod-gray default-transition"
      href={ROUTES.jobs}
    >
      Search Jobs
    </Link>
  </div>
);

export default NotFoundJC;
